var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      class: [
        "card card--widget card--widget--pie bg-danger",
        _vm.classname ? _vm.classname : ""
      ]
    },
    [
      _c("div", { staticClass: "card__body" }, [
        _c(
          "div",
          {
            class: [
              "pie js-pie",
              _vm.drawPieOnLoad ? "js-pie-draw" : "",
              _vm.donughtStyle ? "pie--donught" : ""
            ],
            attrs: {
              "data-pie-id": _vm.id,
              "data-percentage": _vm.percentage,
              "data-decimals": _vm.decimals
            }
          },
          [
            !_vm.externalLabel
              ? _c("span", [_vm._v(_vm._s(_vm.percentage) + "%")])
              : _vm._e()
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "card--widget__label h4" }, [
          _c(
            "span",
            { staticClass: "js-pie-label", attrs: { "data-pie-id": _vm.id } },
            [_vm._v(_vm._s(_vm.percentage) + "%")]
          ),
          _c("br"),
          _vm._v(" "),
          _c("small", { staticClass: "text-uppercase" }, [
            _vm._v(_vm._s(_vm.heading))
          ])
        ])
      ]),
      _vm._v(" "),
      _vm.footer
        ? _c("div", {
            staticClass: "card__footer",
            domProps: { innerHTML: _vm._s(_vm.footer) }
          })
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-500e6d0d", { render: render, staticRenderFns: staticRenderFns })
  }
}