var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form", { staticClass: "card pure-form" }, [
    _vm.header
      ? _c("div", { staticClass: "card__header" }, [
          _c("div", {
            staticClass: "card__header-left",
            domProps: { innerHTML: _vm._s(_vm.header) }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "card__header-right" }, [
            _c(
              "a",
              {
                staticClass: "pure-button button-small close",
                on: {
                  click: function($event) {
                    _vm.$emit("close")
                  }
                }
              },
              [_vm._v("Schließen")]
            )
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "card__body" }, [
      _vm.helpdescription
        ? _c("div", {
            staticClass: "checklist__helpitem checklist__helpdescription",
            domProps: { innerHTML: _vm._s(_vm.helpdescription) }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.helpimages
        ? _c(
            "div",
            { staticClass: "checklist__helpitem checklist__helpimages" },
            [
              _vm.helpimages.length > 0
                ? _c(
                    "carousel",
                    {
                      attrs: {
                        "per-page": 2,
                        "pagination-size": 16,
                        "pagination-padding": 8
                      }
                    },
                    _vm._l(_vm.helpimages, function(image, imagekey) {
                      return _c("slide", { key: imagekey }, [
                        _c("img", {
                          attrs: { src: image.thumbnail, alt: image.title }
                        })
                      ])
                    })
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.helpvideo && _vm.helpvideo.url
        ? _c(
            "div",
            { staticClass: "checklist__helpitem checklist__helpvideo" },
            [
              _vm.helpvideo.html
                ? _c("div", {
                    staticClass: "video",
                    domProps: { innerHTML: _vm._s(_vm.helpvideo.html) }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.helpvideo.authorname
                ? _c("em", [
                    _vm.helpvideo.authorurl
                      ? _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.helpvideo.authorurl,
                              target: "_blank"
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.helpvideo.authorname) +
                                "\n          "
                            )
                          ]
                        )
                      : _vm._e()
                  ])
                : _vm._e()
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.helpdocumentation
        ? _c("div", {
            staticClass: "checklist__helpitem checklist__helpdocumentation",
            domProps: { innerHTML: _vm._s(_vm.helpdocumentation) }
          })
        : _vm._e()
    ]),
    _vm._v(" "),
    _vm.footer
      ? _c(
          "div",
          { class: ["card__footer", _vm.footer_class ? _vm.footer_class : ""] },
          [
            _vm.submit
              ? _c(
                  "button",
                  {
                    staticClass: "pure-button is-stretched bg-brand",
                    attrs: { type: "submit" }
                  },
                  [_vm._v(_vm._s(_vm.submit))]
                )
              : _c("div", [_vm._v(_vm._s(_vm.footer))])
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-db57047a", { render: render, staticRenderFns: staticRenderFns })
  }
}