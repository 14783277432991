var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form", { staticClass: "card pure-form" }, [
    _vm.header
      ? _c("div", { staticClass: "card__header" }, [
          _c("div", {
            staticClass: "card__header-left",
            domProps: { innerHTML: _vm._s(_vm.header) }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "card__header-right" }, [
            _c(
              "a",
              {
                staticClass: "pure-button button-small close",
                on: {
                  click: function($event) {
                    _vm.$emit("close")
                  }
                }
              },
              [_vm._v("Schließen")]
            )
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "card__body" }, [
      _vm.body ? _c("p", [_vm._v(_vm._s(_vm.body))]) : _vm._e(),
      _vm._v(" "),
      _c("input", {
        attrs: { type: "hidden", name: "reference" },
        domProps: { value: _vm.reference }
      }),
      _vm._v(" "),
      _c("textarea", { attrs: { name: "comment" } })
    ]),
    _vm._v(" "),
    _vm.footer
      ? _c(
          "div",
          { class: ["card__footer", _vm.footer_class ? _vm.footer_class : ""] },
          [
            _vm.submit
              ? _c(
                  "button",
                  {
                    staticClass: "pure-button is-stretched bg-brand",
                    attrs: { type: "submit" }
                  },
                  [_vm._v(_vm._s(_vm.submit))]
                )
              : _c("div", [_vm._v(_vm._s(_vm.footer))])
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-fd35d868", { render: render, staticRenderFns: staticRenderFns })
  }
}