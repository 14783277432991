//in your app.js or similar file
import Vue from 'vue';

Vue.component('imagePlaceholder', require('../../node_modules/vuejs-image-placeholder/'));

// https://github.com/javve/list.js
//import List from 'list.js/dist/list.min.js'
//export default List;
//Vue.use(List);



//component name should be in camel-case


// Countdown
// https://github.com/xkeshi/vue-countdown/
import Countdown from '@xkeshi/vue-countdown';
Vue.use(Countdown);
Vue.component('countdown', Countdown);

// Card
import Card from '../components/Card.vue';
Vue.component('card', Card);

// Pie Chart
import PieChart from '../components/PieChart.vue';
Vue.component('pieChart', PieChart);

// Tabs
// https://github.com/spatie/vue-tabs-component
import Tabs from 'vue-tabs-component';
Vue.use(Tabs);

// Table
// https://github.com/spatie/vue-table-component
import TableComponent from 'vue-table-component';
Vue.use(TableComponent, {
  class: 'pure-form',
  tableClass: 'pure-table',
  theadClass: 'table-thead',
  tbodyClass: 'table-body',
  showCaption: false,
  showFilter: false,
  filterPlaceholder: 'Ergebnisse Filtern…',
  filterNoResults: 'Keine Ergebnisse gefunden.',
});

// Range Slider
// https://github.com/NightCatSama/vue-slider-component
import vueSlider from 'vue-slider-component';
Vue.use(vueSlider);

// Collapse/Panel
// https://github.com/roszpun/vue-collapse
import VueCollapse from 'vue2-collapse';
Vue.use(VueCollapse, {
  methods: {
    toggleClass() {
      console.log('here');
    }
  }
})

// MODAL
// https://github.com/euvl/vue-js-modal
import VModal from 'vue-js-modal';
Vue.use(VModal, { dynamic: true });
import ModalHelp from '../components/ModalHelp.vue'
import ModalParticipate from '../components/ModalParticipate.vue'

// Carousel
// https://github.com/SSENSE/vue-carousel
import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);

// Tooltip
// https://github.com/Akryum/v-tooltip
import VTooltip from 'v-tooltip';
import classCallCheck from 'babel-runtime/helpers/classCallCheck';
Vue.use(VTooltip);



var replace_chars = {'ä':'ae','ö':'oe','ü':'ue','ß':'ss','á':'a','à':'a','é':'e','è':'e','ë':'e','ñ':'n','ç':'c',' ':'-', '.':'',':':'',',':'','_':'-',';':'','!':'','?':'','§':'','$':'','€':'','@':'','%':'','&':'','(':'',')':'','=':'','/':'','[':'',']':'','#':'','--':'-'};
var apiURL = '/json' + window.location.pathname.replace('/app/','/');

var app = new Vue({
  el: '#app',
  data: {
    checklist_icon_pencil_text: 'Ihnen fehlen Informationen? Es ist etwas nicht richtig? Schreiben Sie es uns.',
    checklist_icon_help_text: 'Weitere Informationen',
    checklist_score_1: 'Gesehen',
    checklist_score_2: 'Besprochen',
    checklist_score_3: 'Geübt',
    checklist_score_4: 'Sicher',
    checklist_true: 'richtig',
    checklist_false: 'falsch',
    items: '',
    checklist: '',
    sel: 0,
    demoslider: {
      value: 0,
      width: 'auto',
      height: 6,
      direction: 'horizontal',
      dotSize: 16,
      eventType: 'auto',
      min: 0,
      max: 100,
      interval: 1,
      debug: process && process.env && process.env.NODE_ENV !== 'production',
      disabled: false,
      show: true,
      realTime: false,
      tooltip: 'always',
      clickable: true,
      tooltipDir: 'top',
      piecewise: false,
      lazy: false,
      useKeyboard: false,
      reverse: false,
      speed: 0.5,
      formatter: null,
      focusStyle: null,
      bgStyle: null,
      sliderStyle: null,
      tooltipStyle: null,
      processStyle: null,
      piecewiseStyle: null
    }
  },
  created: function () {
    this.fetchData();
    this.presetChecklistAnswers();
  },
  computed: {
  },
  methods: {
    checksum: function(str) {
      var hash = 0, i, chr, len;
      if (str.length === 0) return hash;
      for (i = 0, len = str.length; i < len; i++) {
        chr   = str.charCodeAt(i);
        hash  = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
      }
      return hash;
    },

    onChecklistAnswerChange: function() {
      var el = event.target;
      console.log('change', el);

      this.getChecklistAnswers();
    },

    updateProgressbar: function(progress) {
      var progressbar = this.$refs.progressbar;
      if (progressbar) {
        progressbar.style.width = progress + '%';

        if (progress > 99) {
          progressbar.classList.add('bg-success');
          progressbar.classList.remove('bg-info');
        } else {
          progressbar.classList.remove('bg-success');
          progressbar.classList.add('bg-info');
        }
      }
    },

    presetChecklistAnswers: function() {
      var that = this;

      if (document.getElementById('answers_checklist_answers')) {
        var results = JSON.parse(document.getElementById('answers_checklist_answers').value);

        // wait for results to be rendered
        setTimeout(function(){
          Array.prototype.forEach.call(results.answers, function(el) {
            var query = '[name="'+el.name+'"]';
            var answer = document.querySelector(query);

            if (answer) {
              if (answer.type == 'radio') {
                query = '[name="'+el.name+'"][value="'+el.value+'"]';
                answer = document.querySelector(query);
              }

              answer.checked = true;
            }
          });

          that.updateProgressbar(results.progress);
        },2000);
      }
    },

    getChecklistAnswers: function() {
      var that = this;
      var selectorAnswers = '.js-answer';
      var selectorQuestions = '.js-question';
      var elementsAnswers = document.querySelectorAll(selectorAnswers);
      var elementsQuestions = document.querySelectorAll(selectorQuestions);
      var results = {};
      var t = document.getElementById('answers_checklist_answers');
      var p = document.getElementById('answers_checklist_progress');
      var c = document.getElementById('answers_checklist_certificate');

      // preset all fields
      results.progress = 0;
      results.certificate = 0;
      results.questions = elementsQuestions.length;
      results.answers = [];
      results.answered = 0;
      results.answeredCorrect = 0;

      // get answers
      Array.prototype.forEach.call(elementsAnswers, function(el) {
        var element = {};

        if (el.checked) {
          element = { "name": el.name };
          if (el.type == 'radio') {
            element = { "name": el.name, "value": el.value };
          }
          results.answers.push(element);
        }
      });

      setTimeout(function(){
        var elementsQuestions = document.querySelectorAll(selectorQuestions);

        elementsQuestions.forEach(function(el){
          var elementsAnswersSelected = el.querySelectorAll(selectorAnswers+':checked');
          if (elementsAnswersSelected.length > 0) {
            results.answered++;
          }
          var correctAnswersSelected = el.querySelectorAll(selectorAnswers+':checked[data-check="1"]');
          correctAnswersSelected.forEach(function(cas){
            console.log('cas',cas.nextElementSibling.innerHTML);
          });
          if (correctAnswersSelected.length > 0) {
            results.answeredCorrect++;
          }
          var wrongAnswersSelected = el.querySelectorAll(selectorAnswers+':checked:not([data-check="1"])');
          wrongAnswersSelected.forEach(function(was){
          });
        });

        results.progress = Math.round(results.answered / elementsQuestions.length * 100);
        results.certificate = Math.round(results.answeredCorrect / elementsQuestions.length * 100);
        t.value = JSON.stringify(results);
        p.value = results.progress;
        c.value = results.certificate;
        console.log('certificate',results.certificate);
        that.updateProgressbar(results.progress);
      },100);
    },

    onCountdownEnd: function() {
      this.$refs.countdown.$el.click();
    },

    inactivityTime: function() {
      var t;
      window.onload = resetTimer;
      // DOM Events
      document.onload = resetTimer;
      document.onmousemove = resetTimer;
      document.onmousedown = resetTimer; // touchscreen presses
      document.ontouchstart = resetTimer;
      document.onclick = resetTimer;     // touchpad clicks
      document.onscroll = resetTimer;    // scrolling with arrow keys
      document.onkeypress = resetTimer;

      function logout() {
        alert("You are now logged out.");
        //location.href = 'logout.php'
      }

      function resetTimer() {
        clearTimeout(t);
        t = setTimeout(logout, 3000);
        // 1000 milisec = 1 sec
      }
    },

    modalShow: function(name) {
      console.log(name);
      //this.$modal.show(name);
    },

    modalHide: function(name) {
      //this.$modal.hide(name);
    },

    fetchData: function() {
      let self = this;
      let request = new XMLHttpRequest();

      // only with slug „fragen“ and „listen“
      if (apiURL.indexOf('/fragen') >= 0 || apiURL.indexOf('/listen') >= 0) {
        request.open('GET', apiURL, true);
        console.info('fetchData() apiURL' ,apiURL);
      
        request.onload = function() {
          if (request.status >= 200 && request.status < 400) {
            // Success!
            let json = JSON.parse(request.responseText);

            self.checklist = json.data.attributes;
            self.cid = json.data.id;

            console.log('request.onload',json.data.attributes);

            // split questions
            let questions = {};
            let blockNumber = -1;
            let sectionNumber = -1;
            Array.prototype.forEach.call(self.checklist.questions, function(el) {
              if (el) {
                if (el._block === 'titlesection') {
                  blockNumber++;
                  sectionNumber = -1;
                  Vue.set(questions, blockNumber, {"heading": el.heading, "sections": []});
                }
                                
                if (el._block != 'titlesection') {
                  sectionNumber++;
                  Vue.set(questions[blockNumber].sections, sectionNumber, el);
                }
              }
            });
            self.checklist.questions = questions;

            console.log('self',self);
          } else {
            // We reached our target server, but it returned an error
            console.error('Error!', request.status);
          }
        };
        
        request.onerror = function() {
          // There was a connection error of some sort
        };
        
        request.send();
      }
    },

    onButtonClickSpin: function() {
      let button = event.target;
      if (event.target.type !== 'button') {
        button = button.parentNode;
      }
      var spinner = button.querySelector('[data-spin]');
      spinner.classList.toggle('spin');
      spinner.classList.remove('hidden');
    },

    toggleClass: function() {
      console.log('there');
      return true;
      var el = this.target;
      var className = el.dataset.class;
      var targetId = el.dataset.target ? el.dataset.target : false;
      var target = targetId ? document.querySelectorAll(targetId) : document.getElementsByTagName('BODY')[0];
    
      if (targetId==='self') {
        target = el;
      } else if (targetId==='parent') {
        target = el.parentNode;
      }

      //console.log('el',el);
      //console.log('targetId',targetId);
      //console.log('target',target);

      if (target.classList) {
        target.classList.toggle(className);
      } else {
        var classes = target.className.split(' ');
        var existingIndex = classes.indexOf(className);
        
        if (existingIndex >= 0) {
          classes.splice(existingIndex, 1);
        } else {
          classes.push(className);
        }
        
        target.className = classes.join(' ');
      }
    
      return false;
    },

    showModalParticipate: function(r) {
      this.$modal.show(ModalParticipate, {
        header: 'Schreiben Sie uns',
        body: 'Ihnen fehlen Informationen? Sie möchten etwas ergänzen? Gerne.',
        reference: r,
        submit: 'Absenden',
        footer: 'button',
        footer_class: 'is-frameless'
      }, {
        adaptive: true,
        classes: 'modal',
        draggable: '.card__header',
        height: 'auto',
        name: 'modal-participate',
        scrollable: true
      })
    },

    showModalHelp: function(title,desc,images,video,docu) {
      this.$modal.show(ModalHelp, {
        header: title,
        helpdescription: desc,
        helpimages: images,
        helpvideo: video,
        helpdocumentation: docu
      }, {
        adaptive: true,
        classes: 'modal',
        draggable: '.card__header',
        height: 'auto',
        name: 'modal-help',
        scrollable: true,
        width: 800
      })
    }
  }
});



// test
//Vue.component('pie', {
//  template: '<svg viewBox="0 0 32 32"><circle r="16" cx="16" cy="16" style="stroke-dasharray: 10 100" /></svg>'
//});


// pure JavaScript Functions - need all to be converted in Vue.JS and well structured in separate files

/*
// Draw Pie
var drawPie = (id, percentage) => {
  var elements = document.querySelectorAll('.js-pie[data-pie-id="'+id+'"]');
  Array.prototype.forEach.call(elements, function(el, elIndex) {
    var label = document.createElement('div');
    var labelClassName = 'pie__label';
    var num = (percentage>=0) ? +percentage : +el.getAttribute('data-percentage');
    var numLabel = num;
    var chart = '<svg viewBox="0 0 32 32"><circle r="15.91549430918954" cx="16" cy="16" class="active" /><circle r="15.91549430918954" cx="16" cy="16" class="bg" /></svg>';
  
    // there is a little space on top if it is 100% so we do this little workaround
    if (num > 99) {
      num = 101;
    }
  
    // insert svg if not existing
    if (el.querySelector('svg') === null) {
      el.innerHTML = chart;
    }
  
    el.querySelector('circle').style.strokeDasharray = num + ' 100';
  
    label.className = labelClassName;
    label.innerHTML = numLabel + '%';
  
    // append element if not existing
    if (el.querySelector('.'+labelClassName) === null) {
      el.appendChild(label);
    }
    // else update content
    else {
      var countupLabel = el.querySelector('.'+labelClassName);
      var countupStart = parseFloat(countupLabel.innerHTML);
      var countupDecimals = el.getAttribute('data-decimals') ? el.getAttribute('data-decimals') : 1;
      var cardElement = el.parentNode.parentNode;

      el.setAttribute('data-percentage', num);
  
      if (cardElement.classList.contains('card')) {
        cardElement.classList.remove('bg-success');
        cardElement.classList.remove('bg-warning');
        cardElement.classList.remove('bg-danger');
    
        if (num>=80) {
          cardElement.classList.add('bg-success');
        } else if (num>=40 && num<80) {
          cardElement.classList.add('bg-warning');
        } else {
          cardElement.classList.add('bg-danger');
        }
      }

      //countupLabel.innerHTML = numLabel + '%';
      var countupOptions = {
        decimal: ',',
        separator: ',',
        suffix: '%',
        useEasing: true,
        useGrouping: false
      };
      // element, from, to, decimals, duration
      // @see http://inorganik.github.io/countUp.js/
      var countup = new CountUp(countupLabel, countupStart, numLabel, countupDecimals, 2.5, countupOptions);
      if (!countup.error) {
        countup.start();
      } else {
        console.error(countup.error);
      }
    }  
  });

  var elements = document.querySelectorAll('.js-pie-label[data-pie-id="'+id+'"]');
  Array.prototype.forEach.call(elements, function(el, elIndex) {
    var countupLabel = el;
    var countupStart = parseFloat(countupLabel.innerHTML);
    var countupDecimals = el.getAttribute('data-decimals') ? el.getAttribute('data-decimals') : 1;
    var num = (percentage>=0) ? +percentage : +el.getAttribute('data-percentage');
    var numLabel = num;
 
    el.setAttribute('data-percentage', num);

    //countupLabel.innerHTML = numLabel + '%';
    var countupOptions = {
      decimal: ',',
      separator: ',',
      suffix: '%',
      useEasing: true,
      useGrouping: false
    };
    // element, from, to, decimals, duration
    // @see http://inorganik.github.io/countUp.js/
    var countup = new CountUp(countupLabel, countupStart, numLabel, countupDecimals, 2.5, countupOptions);
    if (!countup.error) {
      countup.start();
    } else {
      console.error(countup.error);
    }
  });
};

// Pie Container
var pieContainer = () => {
  // loop all containers
  var elements = document.querySelectorAll('.js-pie-container');
  Array.prototype.forEach.call(elements, function(element, elementIndex) {
    var containerScore = 0;
    var containerValue = 0;
    var percentage = 0;
    var id = element.getAttribute('data-pie-id');

    // loop all question groups
    var groupElements = element.querySelectorAll('.js-pie-group');
    Array.prototype.forEach.call(groupElements, function(groupElement, groupElementIndex) {
      var scoreElement = groupElement.querySelector('.js-pie-score');
      if (scoreElement) {
        var scoreValue = (scoreElement.getAttribute('data-score') > 0) ? parseFloat(scoreElement.getAttribute('data-score')) : 1;

        // loop all question group items
        var itemElements = groupElement.querySelectorAll('.js-pie-item');
        Array.prototype.forEach.call(itemElements, function(itemElement, itemElementIndex) {
          var itemScore = (itemElement.getAttribute('data-score') > 0) ? parseFloat(itemElement.getAttribute('data-score')) : 1;
  
          // count each checkbox
          if (itemElement.type === 'checkbox') {
            var itemValue = itemElement.checked ? (itemScore * scoreValue) : 0;
          }
  
          // count all score points and all checked ones
          containerScore += (itemScore * scoreValue);
          containerValue += itemValue;
        });
      }
    });

    percentage = parseFloat(containerValue / containerScore * 100);

    // draw pie - the pie element js-pie has to be the previous element of js-pie-container
    //var pieElement = element.previousElementSibling;
    drawPie(id, percentage);
  });
};
pieContainer();

var pieItemDrawer = () => {
  var elements = document.querySelectorAll('.js-pie-draw');

  // loop all items
  Array.prototype.forEach.call(elements, function(element, elementIndex) {
    var id = element.getAttribute('data-pie-id');
    var percentage = element.getAttribute('data-percentage');
    drawPie(id, percentage);
  });
};
pieItemDrawer();

var pieItemListener = () => {
  var elements = document.querySelectorAll('.js-pie-item');
  // loop all items
  Array.prototype.forEach.call(elements, function(element, elementIndex) {
    element.addEventListener('click', pieContainer);
  });
};
pieItemListener();
*/
var toggleClass = (curr) => {
  var el = curr.target;
  var className = el.dataset.class;
  var targetId = el.dataset.target ? el.dataset.target : false;
  var target = targetId ? document.querySelectorAll(targetId) : document.getElementsByTagName('BODY')[0];

  if (targetId==='self') {
    target = el;
  } else if (targetId==='parent') {
    target = el.parentNode;
  }

  //console.log('el',el);
  //console.log('targetId',targetId);
  //console.log('target',target);

  if (target.classList) {
    target.classList.toggle(className);
  } else {
    var classes = target.className.split(' ');
    var existingIndex = classes.indexOf(className);
  
    if (existingIndex >= 0) {
      classes.splice(existingIndex, 1);
    } else {
      classes.push(className);
    }
  
    target.className = classes.join(' ');
  }

  return false;
};

var toggleListener = () => {
  var elements = document.querySelectorAll('.js-toggle');

  // loop all items
  Array.prototype.forEach.call(elements, function(element, elementIndex) {
    element.addEventListener('click', toggleClass);
  });
};
toggleListener();

var getZcons = () => {
  var el = document.querySelectorAll('.js-zcons');
  if (el.length > 0) {
    var container = el[0];
    var url = container.getAttribute('data-url');
    var request = new XMLHttpRequest();
    request.open('GET', url, true);
  
    request.onload = function() {
      if (request.status >= 200 && request.status < 400) {
        // Success!
        var data = JSON.parse(request.responseText);
        var prefix = data.css_prefix_text;
        // loop all items
        Array.prototype.forEach.call(data.glyphs, function(element, elementIndex) {
          var css = element.css;
          var iconGrid = document.createElement('div');
          iconGrid.className = 'pure-u-1-2 pure-u-md-1-4 pure-u-xl-1-8';
          if (css!='grunt' && css!='bower') {
            iconGrid.innerHTML = '<span class="'+prefix+css+'" style="display:block;text-align:center;margin-top:16px"></span><em style="display:block;text-align:center;margin-bottom:16px;line-height:1">' + css + '</em>';
            container.appendChild(iconGrid);
          }
        });
      } else {
        // We reached our target server, but it returned an error
        console.error('error', url);
      }
    };
  
    request.onerror = function() {
      // There was a connection error of some sort
    };
  
    request.send();
  }
}
getZcons();