<template>
  <section itemscope
    v-bind:class="[
      'card js-pie',
      cardStyle ? 'card--'+cardStyle : '',
      widget ? 'card--widget card--widget--'+widget : '',
      (widget=='profile' || widget=='contact') ? 'vcard' : '',
      clickable ? 'card--clickable' : '',
      editable ? 'card--editable' : '',
      markable ? 'card--markable' : ''
    ]"
    v-bind:itemtype="[
      (widget=='profile' || widget=='contact') ? 'http://schema.org/Person':''
    ]"
    >
    <ul class="card__menu" v-if="editable || markable">
      <li class="card__menu-item" v-if="editable">
        <a href="#" class="bounce-hover"><span class="zcon-bookmark-empty"></span></a>
      </li>
      <li class="card__menu-item" v-if="markable">
        <a href="#" class="bounce-hover"><span class="zcon-pencil"></span></a>
      </li>
    </ul>

    <header class="card__header" v-if="header">
      <div class="card__header-left" v-html="header"></div>
      <div class="card__header-right" v-if="header_right" v-html="header_right"></div>
    </header>

    <div class="card__body">

      <div v-if="widget=='pie'">
        <pie-chart :heading="item.heading" :donught-style="true" :percentage="widget_value" :id="widget_id" :draw="widget_draw"></pie-chart>
        <div class="card--widget__label h4">
          <span class="js-pie-label" :data-pie-id="widget_id">{{ widget_value }}%</span><br>
          <small class="text-uppercase">{{ widget_label }}</small>
        </div>
      </div>

      <div v-if="widget=='facebook'">
        <div class="card--widget__icon">
          <span class="zcon-facebook"></span>
        </div>
        <div class="card--widget__label h4">
          <span>{{ data.likes }}</span><br>
          <small class="text-uppercase">Likes</small>
        </div>
        <div class="card--widget__label h4">
          <span>{{ data.feeds }}</span><br>
          <small class="text-uppercase">Feeds</small>
        </div>
      </div>

      <div v-if="widget=='twitter'">
        <div class="card--widget__icon">
          <span class="zcon-twitter"></span>
        </div>
        <div class="card--widget__label h4">
          <span>{{ data.followers }}</span><br>
          <small class="text-uppercase">Followers</small>
        </div>
        <div class="card--widget__label h4">
          <span>{{ data.tweets }}</span><br>
          <small class="text-uppercase">Tweets</small>
        </div>
      </div>

      <div v-if="widget=='contact'">
        <div class="card--widget__image">
          <img itemprop="image" class="photo" :src="data.image" alt="Profilbild">
        </div>
        <div class="card--widget__label h4">
          <span itemprop="name" class="fn" v-if="data.name">{{ data.name }}</span><br>
          <small class="text-uppercase category" itemprop="jobTitle" v-if="data.jobtitle">{{ data.jobtitle }}</small><br>
          <a itemprop="telephone" :href="data.telephone.replace(' ','').replace('/','')" class="text-uppercase tel" v-if="data.telephone">
            <small>{{ data.telephone.replace('+49','0') }}</small></a>
          <br>
          <a itemprop="email" :href="data.email" :title="data.email" class="card--widget__link bounce-hover email" v-if="data.email">
            <small class="zcon-mail"></small>
          </a>
          <a itemprop="url" :href="data.facebook" :title="data.facebook" class="card--widget__link bounce-hover url" v-if="data.facebook">
            <small class="zcon-facebook"></small>
          </a>
          <a itemprop="url" :href="data.twitter" :title="data.twitter" class="card--widget__link bounce-hover url" v-if="data.twitter">
            <small class="zcon-twitter"></small>
          </a>
          <a itemprop="url" :href="data.instagram" :title="data.instagram" class="card--widget__link bounce-hover url" v-if="data.instagram">
            <small class="zcon-instagram"></small>
          </a>
        </div>
      </div>

      <div v-if="widget=='profile'">
        <div class="card--widget__image">
          <img itemprop="image" class="photo" :src="data.image" alt="Profilbild">
        </div>
        <div class="card--widget__label h4 text-center">
          <span itemprop="name" class="fn" v-if="data.name">{{ data.name }}</span><br>
          <small class="text-uppercase category" itemprop="jobTitle" v-if="data.jobtitle">{{ data.jobtitle }}</small><br>
          <a itemprop="telephone" :href="data.telephone.replace(' ','').replace('/','')" class="text-uppercase tel" v-if="data.telephone">{{ data.telephone.replace('+49','0') }}</a><br>
          <a itemprop="email" :href="data.email" :title="data.email" class="card--widget__link bounce-hover email" v-if="data.email">
            <small class="zcon-mail"></small>
          </a>
          <a itemprop="url" :href="data.facebook" :title="data.facebook" class="card--widget__link bounce-hover url" v-if="data.facebook">
            <small class="zcon-facebook"></small>
          </a>
          <a itemprop="url" :href="data.twitter" :title="data.twitter" class="card--widget__link bounce-hover url" v-if="data.twitter">
            <small class="zcon-twitter"></small>
          </a>
          <a itemprop="url" :href="data.instagram" :title="data.instagram" class="card--widget__link bounce-hover url" v-if="data.instagram">
            <small class="zcon-instagram"></small>
          </a>
          <p v-if="data.description">
            <small itemprop="description" class="note">{{ data.description }}</small>
          </p>
        </div>
      </div>
    
      <div class="card__body-html" v-html="body" v-if="body"></div>
    </div>

    <footer class="card__footer" v-if="footer" v-html="footer"></footer>
  </section>
</template>

<script>
export default {
  name: 'Card',
  props: ['cardStyle','widget','header','body','footer','data','clickable','editable','markable']
}
</script>