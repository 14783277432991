<template>
  <section
    v-bind:class="[
      'card card--widget card--widget--pie bg-danger',
      classname? classname : ''
    ]">
    <div class="card__body">
      <div
        v-bind:class="[
          'pie js-pie',
          drawPieOnLoad ? 'js-pie-draw' : '',
          donughtStyle ? 'pie--donught':''
        ]" :data-pie-id="id" :data-percentage="percentage" :data-decimals="decimals">
        <span v-if="!externalLabel">{{ percentage }}%</span>
      </div>
      <div class="card--widget__label h4">
        <span class="js-pie-label" :data-pie-id="id">{{ percentage }}%</span><br>
        <small class="text-uppercase">{{ heading }}</small>
      </div>
    </div>
    <div class="card__footer" v-if="footer" v-html="footer"></div>
  </section>
</template>

<script>
export default {
  name: 'PieChart',
  props: ['drawPieOnLoad','donughtStyle','id','percentage','decimals','externalLabel','heading','footer','classname'],
  created: function () {
    var self = this;
    setTimeout(function(){
      self.pieContainer();
      self.pieItemDrawer();
      self.pieItemListener();
    },2000);
  },
  methods: {
    // Draw Pie
    drawPie(id, percentage) {
      var self = this;
      var elements = this.$root.$el.querySelectorAll('.js-pie[data-pie-id="'+id+'"]');
      Array.prototype.forEach.call(elements, function(el, elIndex) {
        var label = document.createElement('div');
        var labelClassName = 'pie__label';
        var num = (percentage>=0) ? +percentage : +el.getAttribute('data-percentage');
        var numLabel = num;
        var chart = '<svg viewBox="0 0 32 32"><circle r="15.91549430918954" cx="16" cy="16" class="active" /><circle r="15.91549430918954" cx="16" cy="16" class="bg" /></svg>';
      
        // there is a little space on top if it is 100% so we do this little workaround
        if (num > 99) {
          num = 101;
        }
      
        // insert svg if not existing
        if (el.querySelector('svg') === null) {
          el.innerHTML = chart;
        }
      
        el.querySelector('circle').style.strokeDasharray = num + ' 100';
      
        label.className = labelClassName;
        label.innerHTML = numLabel + '%';
      
        // append element if not existing
        if (el.querySelector('.'+labelClassName) === null) {
          el.appendChild(label);
        }
        // else update content
        else {
          var countupLabel = el.querySelector('.'+labelClassName);
          var countupStart = parseFloat(countupLabel.innerHTML);
          var countupDecimals = el.getAttribute('data-decimals') ? el.getAttribute('data-decimals') : 1;
          var cardElement = el.parentNode.parentNode;

          el.setAttribute('data-percentage', num);
      
          if (cardElement.classList.contains('card')) {
            cardElement.classList.remove('bg-success');
            cardElement.classList.remove('bg-warning');
            cardElement.classList.remove('bg-danger');
        
            if (num>=80) {
              cardElement.classList.add('bg-success');
            } else if (num>=40 && num<80) {
              cardElement.classList.add('bg-warning');
            } else {
              cardElement.classList.add('bg-danger');
            }
          }

          //countupLabel.innerHTML = numLabel + '%';
          var countupOptions = {
            decimal: ',',
            separator: ',',
            suffix: '%',
            useEasing: true,
            useGrouping: false
          };

          //import ICountUp from 'vue-countup-v2';
          var CountUp = require('../../node_modules/countup.js/dist/countUp.js');

          // element, from, to, decimals, duration
          // @see http://inorganik.github.io/countUp.js/
          if (typeof CountUp !== "undefined") {
            var countup = new CountUp(countupLabel, countupStart, numLabel, countupDecimals, 2.5, countupOptions);
            if (!countup.error) {
              countup.start();
            } else {
              console.error(countup.error);
            }
          }
        }  
      });

      var elements = this.$root.$el.querySelectorAll('.js-pie-label[data-pie-id="'+id+'"]');
      Array.prototype.forEach.call(elements, function(el, elIndex) {
        var countupLabel = el;
        var countupStart = parseFloat(countupLabel.innerHTML);
        var countupDecimals = el.getAttribute('data-decimals') ? el.getAttribute('data-decimals') : 1;
        var num = (percentage>=0) ? +percentage : +el.getAttribute('data-percentage');
        var numLabel = num;
    
        el.setAttribute('data-percentage', num);

        //countupLabel.innerHTML = numLabel + '%';
        var countupOptions = {
          decimal: ',',
          separator: ',',
          suffix: '%',
          useEasing: true,
          useGrouping: false
        };

        //import ICountUp from 'vue-countup-v2';
        var CountUp = require('../../node_modules/countup.js/dist/countUp.js');

        // element, from, to, decimals, duration
        // @see http://inorganik.github.io/countUp.js/
        if (typeof CountUp !== "undefined") {
          var countup = new CountUp(countupLabel, countupStart, numLabel, countupDecimals, 2.5, countupOptions);
          if (!countup.error) {
            countup.start();
          } else {
            console.error(countup.error);
          }
        }
      });
    },

    // Pie Container
    pieContainer() {
      var self = this;
      // loop all containers
      var elements = this.$root.$el.querySelectorAll('.js-pie-container');
      Array.prototype.forEach.call(elements, function(element, elementIndex) {
        var containerScore = 0;
        var containerValue = 0;
        var percentage = 0;
        var id = element.getAttribute('data-pie-id');

        // loop all question groups
        var groupElements = element.querySelectorAll('.js-pie-group');
        Array.prototype.forEach.call(groupElements, function(groupElement, groupElementIndex) {
          var scoreElement = groupElement.querySelector('.js-pie-score');
          if (scoreElement) {
            var scoreValue = (scoreElement.getAttribute('data-score') > 0) ? parseFloat(scoreElement.getAttribute('data-score')) : 1;

            // loop all question group items
            var itemElements = groupElement.querySelectorAll('.js-pie-item');
            Array.prototype.forEach.call(itemElements, function(itemElement, itemElementIndex) {
              var itemScore = (itemElement.getAttribute('data-score') > 0) ? parseFloat(itemElement.getAttribute('data-score')) : 1;
      
              // count each checkbox
              if (itemElement.type === 'checkbox') {
                var itemValue = itemElement.checked ? (itemScore * scoreValue) : 0;
              }
      
              // count all score points and all checked ones
              containerScore += (itemScore * scoreValue);
              containerValue += itemValue;
            });
          }
        });

        percentage = parseFloat(containerValue / containerScore * 100);

        // draw pie - the pie element js-pie has to be the previous element of js-pie-container
        //var pieElement = element.previousElementSibling;
        self.drawPie(id, percentage);
      });
    },

    pieItemDrawer() {
      var self = this;
      var elements = this.$root.$el.querySelectorAll('.js-pie-draw');

      // loop all items
      Array.prototype.forEach.call(elements, function(element, elementIndex) {
        var id = element.getAttribute('data-pie-id');
        var percentage = element.getAttribute('data-percentage');
        self.drawPie(id, percentage);
      });
    },

    pieItemListener() {
      var self = this;
      var elements = this.$root.$el.querySelectorAll('.js-pie-item');
      // loop all items
      Array.prototype.forEach.call(elements, function(element, elementIndex) {
        element.addEventListener('click', self.pieContainer);
        //console.log('pieItemListener', elementIndex, element);
      });
    }
  }
}
</script>