<template>
  <form class="card pure-form">
    <div class="card__header" v-if="header">
      <div class="card__header-left" v-html="header"></div>
      <div class="card__header-right">
        <a class="pure-button button-small close" @click="$emit('close')">Schließen</a>
      </div>
    </div>
    <div class="card__body">
      <p v-if="body">{{ body }}</p>
      <input type="hidden" :value="reference" name="reference">
      <textarea name="comment"></textarea>
    </div>
    <div v-bind:class="['card__footer', footer_class ? footer_class : '' ]" v-if="footer">
      <button class="pure-button is-stretched bg-brand" type="submit" v-if="submit">{{ submit }}</button>
      <div v-else>{{ footer }}</div>
    </div>
  </form>
</template>

<script>
export default {
  name: 'ModalParticipate',
  props: ['header_class','header','body','footer','footer_class','submit','reference']
}
</script>