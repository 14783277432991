<template>
  <form class="card pure-form">
    <div class="card__header" v-if="header">
      <div class="card__header-left" v-html="header"></div>
      <div class="card__header-right">
        <a class="pure-button button-small close" @click="$emit('close')">Schließen</a>
      </div>
    </div>

    <div class="card__body">
      <div class="checklist__helpitem checklist__helpdescription" v-if="helpdescription" v-html="helpdescription">
      </div>

      <div class="checklist__helpitem checklist__helpimages" v-if="helpimages">
        <carousel :per-page="2" :pagination-size="16" :pagination-padding="8" v-if="helpimages.length > 0">
          <slide v-for="(image,imagekey) in helpimages" :key="imagekey">
            <img :src="image.thumbnail" :alt="image.title">
          </slide>
        </carousel>
      </div>

      <div class="checklist__helpitem checklist__helpvideo" v-if="helpvideo && helpvideo.url">
        <div class="video" v-if="helpvideo.html" v-html="helpvideo.html"></div>
        <em v-if="helpvideo.authorname">
          <a :href="helpvideo.authorurl" target="_blank" v-if="helpvideo.authorurl">
            {{ helpvideo.authorname }}
          </a>
        </em>
<!--
      Helpivideo:<br>
      __url: {{ video.url }}<br>
      __width: {{ video.width }}<br>
      __height: {{ video.height }}<br>
      __title: {{ video.title }}<br>
      __authorname: {{ video.authorname }}<br>
      __ratio: {{ video.ratio }}<br>
      __authorurl: {{ video.authorurl }}<br>
      __iframe: {{ video.html }}<br>
      __thumbnail: {{ video.thumbnail }}<br>
-->
      </div>

      <div class="checklist__helpitem checklist__helpdocumentation" v-if="helpdocumentation" v-html="helpdocumentation"></div>
    </div>

    <div v-bind:class="['card__footer', footer_class ? footer_class : '' ]" v-if="footer">
      <button class="pure-button is-stretched bg-brand" type="submit" v-if="submit">{{ submit }}</button>
      <div v-else>{{ footer }}</div>
    </div>
  </form>
</template>

<script>
export default {
  name: 'ModalHelp',
  props: ['header','footer','footer_class','helpdescription','helpimages','helpvideo','helpdocumentation']
}
</script>