var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      class: [
        "card js-pie",
        _vm.cardStyle ? "card--" + _vm.cardStyle : "",
        _vm.widget ? "card--widget card--widget--" + _vm.widget : "",
        _vm.widget == "profile" || _vm.widget == "contact" ? "vcard" : "",
        _vm.clickable ? "card--clickable" : "",
        _vm.editable ? "card--editable" : "",
        _vm.markable ? "card--markable" : ""
      ],
      attrs: {
        itemscope: "",
        itemtype: [
          _vm.widget == "profile" || _vm.widget == "contact"
            ? "http://schema.org/Person"
            : ""
        ]
      }
    },
    [
      _vm.editable || _vm.markable
        ? _c("ul", { staticClass: "card__menu" }, [
            _vm.editable
              ? _c("li", { staticClass: "card__menu-item" }, [_vm._m(0)])
              : _vm._e(),
            _vm._v(" "),
            _vm.markable
              ? _c("li", { staticClass: "card__menu-item" }, [_vm._m(1)])
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.header
        ? _c("header", { staticClass: "card__header" }, [
            _c("div", {
              staticClass: "card__header-left",
              domProps: { innerHTML: _vm._s(_vm.header) }
            }),
            _vm._v(" "),
            _vm.header_right
              ? _c("div", {
                  staticClass: "card__header-right",
                  domProps: { innerHTML: _vm._s(_vm.header_right) }
                })
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "card__body" }, [
        _vm.widget == "pie"
          ? _c(
              "div",
              [
                _c("pie-chart", {
                  attrs: {
                    heading: _vm.item.heading,
                    "donught-style": true,
                    percentage: _vm.widget_value,
                    id: _vm.widget_id,
                    draw: _vm.widget_draw
                  }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "card--widget__label h4" }, [
                  _c(
                    "span",
                    {
                      staticClass: "js-pie-label",
                      attrs: { "data-pie-id": _vm.widget_id }
                    },
                    [_vm._v(_vm._s(_vm.widget_value) + "%")]
                  ),
                  _c("br"),
                  _vm._v(" "),
                  _c("small", { staticClass: "text-uppercase" }, [
                    _vm._v(_vm._s(_vm.widget_label))
                  ])
                ])
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.widget == "facebook"
          ? _c("div", [
              _vm._m(2),
              _vm._v(" "),
              _c("div", { staticClass: "card--widget__label h4" }, [
                _c("span", [_vm._v(_vm._s(_vm.data.likes))]),
                _c("br"),
                _vm._v(" "),
                _c("small", { staticClass: "text-uppercase" }, [
                  _vm._v("Likes")
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "card--widget__label h4" }, [
                _c("span", [_vm._v(_vm._s(_vm.data.feeds))]),
                _c("br"),
                _vm._v(" "),
                _c("small", { staticClass: "text-uppercase" }, [
                  _vm._v("Feeds")
                ])
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.widget == "twitter"
          ? _c("div", [
              _vm._m(3),
              _vm._v(" "),
              _c("div", { staticClass: "card--widget__label h4" }, [
                _c("span", [_vm._v(_vm._s(_vm.data.followers))]),
                _c("br"),
                _vm._v(" "),
                _c("small", { staticClass: "text-uppercase" }, [
                  _vm._v("Followers")
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "card--widget__label h4" }, [
                _c("span", [_vm._v(_vm._s(_vm.data.tweets))]),
                _c("br"),
                _vm._v(" "),
                _c("small", { staticClass: "text-uppercase" }, [
                  _vm._v("Tweets")
                ])
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.widget == "contact"
          ? _c("div", [
              _c("div", { staticClass: "card--widget__image" }, [
                _c("img", {
                  staticClass: "photo",
                  attrs: {
                    itemprop: "image",
                    src: _vm.data.image,
                    alt: "Profilbild"
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "card--widget__label h4" }, [
                _vm.data.name
                  ? _c(
                      "span",
                      { staticClass: "fn", attrs: { itemprop: "name" } },
                      [_vm._v(_vm._s(_vm.data.name))]
                    )
                  : _vm._e(),
                _c("br"),
                _vm._v(" "),
                _vm.data.jobtitle
                  ? _c(
                      "small",
                      {
                        staticClass: "text-uppercase category",
                        attrs: { itemprop: "jobTitle" }
                      },
                      [_vm._v(_vm._s(_vm.data.jobtitle))]
                    )
                  : _vm._e(),
                _c("br"),
                _vm._v(" "),
                _vm.data.telephone
                  ? _c(
                      "a",
                      {
                        staticClass: "text-uppercase tel",
                        attrs: {
                          itemprop: "telephone",
                          href: _vm.data.telephone
                            .replace(" ", "")
                            .replace("/", "")
                        }
                      },
                      [
                        _c("small", [
                          _vm._v(_vm._s(_vm.data.telephone.replace("+49", "0")))
                        ])
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _vm.data.email
                  ? _c(
                      "a",
                      {
                        staticClass: "card--widget__link bounce-hover email",
                        attrs: {
                          itemprop: "email",
                          href: _vm.data.email,
                          title: _vm.data.email
                        }
                      },
                      [_c("small", { staticClass: "zcon-mail" })]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.data.facebook
                  ? _c(
                      "a",
                      {
                        staticClass: "card--widget__link bounce-hover url",
                        attrs: {
                          itemprop: "url",
                          href: _vm.data.facebook,
                          title: _vm.data.facebook
                        }
                      },
                      [_c("small", { staticClass: "zcon-facebook" })]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.data.twitter
                  ? _c(
                      "a",
                      {
                        staticClass: "card--widget__link bounce-hover url",
                        attrs: {
                          itemprop: "url",
                          href: _vm.data.twitter,
                          title: _vm.data.twitter
                        }
                      },
                      [_c("small", { staticClass: "zcon-twitter" })]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.data.instagram
                  ? _c(
                      "a",
                      {
                        staticClass: "card--widget__link bounce-hover url",
                        attrs: {
                          itemprop: "url",
                          href: _vm.data.instagram,
                          title: _vm.data.instagram
                        }
                      },
                      [_c("small", { staticClass: "zcon-instagram" })]
                    )
                  : _vm._e()
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.widget == "profile"
          ? _c("div", [
              _c("div", { staticClass: "card--widget__image" }, [
                _c("img", {
                  staticClass: "photo",
                  attrs: {
                    itemprop: "image",
                    src: _vm.data.image,
                    alt: "Profilbild"
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "card--widget__label h4 text-center" }, [
                _vm.data.name
                  ? _c(
                      "span",
                      { staticClass: "fn", attrs: { itemprop: "name" } },
                      [_vm._v(_vm._s(_vm.data.name))]
                    )
                  : _vm._e(),
                _c("br"),
                _vm._v(" "),
                _vm.data.jobtitle
                  ? _c(
                      "small",
                      {
                        staticClass: "text-uppercase category",
                        attrs: { itemprop: "jobTitle" }
                      },
                      [_vm._v(_vm._s(_vm.data.jobtitle))]
                    )
                  : _vm._e(),
                _c("br"),
                _vm._v(" "),
                _vm.data.telephone
                  ? _c(
                      "a",
                      {
                        staticClass: "text-uppercase tel",
                        attrs: {
                          itemprop: "telephone",
                          href: _vm.data.telephone
                            .replace(" ", "")
                            .replace("/", "")
                        }
                      },
                      [_vm._v(_vm._s(_vm.data.telephone.replace("+49", "0")))]
                    )
                  : _vm._e(),
                _c("br"),
                _vm._v(" "),
                _vm.data.email
                  ? _c(
                      "a",
                      {
                        staticClass: "card--widget__link bounce-hover email",
                        attrs: {
                          itemprop: "email",
                          href: _vm.data.email,
                          title: _vm.data.email
                        }
                      },
                      [_c("small", { staticClass: "zcon-mail" })]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.data.facebook
                  ? _c(
                      "a",
                      {
                        staticClass: "card--widget__link bounce-hover url",
                        attrs: {
                          itemprop: "url",
                          href: _vm.data.facebook,
                          title: _vm.data.facebook
                        }
                      },
                      [_c("small", { staticClass: "zcon-facebook" })]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.data.twitter
                  ? _c(
                      "a",
                      {
                        staticClass: "card--widget__link bounce-hover url",
                        attrs: {
                          itemprop: "url",
                          href: _vm.data.twitter,
                          title: _vm.data.twitter
                        }
                      },
                      [_c("small", { staticClass: "zcon-twitter" })]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.data.instagram
                  ? _c(
                      "a",
                      {
                        staticClass: "card--widget__link bounce-hover url",
                        attrs: {
                          itemprop: "url",
                          href: _vm.data.instagram,
                          title: _vm.data.instagram
                        }
                      },
                      [_c("small", { staticClass: "zcon-instagram" })]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.data.description
                  ? _c("p", [
                      _c(
                        "small",
                        {
                          staticClass: "note",
                          attrs: { itemprop: "description" }
                        },
                        [_vm._v(_vm._s(_vm.data.description))]
                      )
                    ])
                  : _vm._e()
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.body
          ? _c("div", {
              staticClass: "card__body-html",
              domProps: { innerHTML: _vm._s(_vm.body) }
            })
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.footer
        ? _c("footer", {
            staticClass: "card__footer",
            domProps: { innerHTML: _vm._s(_vm.footer) }
          })
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { staticClass: "bounce-hover", attrs: { href: "#" } }, [
      _c("span", { staticClass: "zcon-bookmark-empty" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { staticClass: "bounce-hover", attrs: { href: "#" } }, [
      _c("span", { staticClass: "zcon-pencil" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card--widget__icon" }, [
      _c("span", { staticClass: "zcon-facebook" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card--widget__icon" }, [
      _c("span", { staticClass: "zcon-twitter" })
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-f883d62a", { render: render, staticRenderFns: staticRenderFns })
  }
}